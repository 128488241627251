/* eslint-disable import/prefer-default-export */
function toBoolean(value) {
  try {
    return eval(value);
  } catch (error) {
    console.error('toBoolean() conversion is failed');
    return Boolean(value);
  }
}

/**
 * Data la chiave viene restituito il valore del paramentro
 * in query string
 * @param {valore ricercato} param
 * @type {tipologia valore ricercato} type
 */
export function getUrlQueryParam(param, type) {
  const urlParams = new URLSearchParams(window.location.search);
  const valueParam = urlParams.get(param);
  switch (type) {
  case 'Boolean':
    return toBoolean(valueParam);
  default:
    return valueParam;
  }
}

/**
 * Data una stringa che rappresenta una url e chiave ricercata viene restituito il valore
 * della chiave
 * @param {stringa url} string
 * @param {valore ricercato} param
 * @type {tipologia valore ricercato} type
 */
export function getQueryParam(string, param, type) {
  const urlParams = new URLSearchParams(string);
  const valueParam = urlParams.get(param);
  switch (type) {
  case 'Boolean':
    return toBoolean(valueParam);
  default:
    return valueParam;
  }
}

export function paramFromDataAttribute(params, param) {
  if (params && params[param] !== '' && params[param] !== undefined) {
    return params[param];
  }
  // coesistenza gestione parametri query e path
  return getUrlQueryParam(param);
}
/** Gestione parametri tramite path, restituiti come data attributes jsp */

export function getParamsFromDataAttributes(selector) {
  const mainElement = document.querySelector(selector);
  const {
    p, c, ass, event, article, paese, project, type, content, prodotto, codIso,
  } = mainElement.dataset;
  const dataAttributes = {
    p,
    c,
    ass,
    event,
    article,
    paese,
    project,
    type,
    content,
    prodotto,
    codIso,
  };
  return {
    p: paramFromDataAttribute(dataAttributes, 'p'),
    c: paramFromDataAttribute(dataAttributes, 'c'),
    ass: paramFromDataAttribute(dataAttributes, 'ass'),
    event: paramFromDataAttribute(dataAttributes, 'event'),
    article: paramFromDataAttribute(dataAttributes, 'article'),
    paese: paramFromDataAttribute(dataAttributes, 'paese'),
    project: paramFromDataAttribute(dataAttributes, 'project'),
    type: paramFromDataAttribute(dataAttributes, 'type'),
    content: paramFromDataAttribute(dataAttributes, 'content'),
    prodotto: paramFromDataAttribute(dataAttributes, 'prodotto'),
    codIso: paramFromDataAttribute(dataAttributes, 'codIso'),
  };
}
