/* eslint-disable import/prefer-default-export */
const baseUrl = '/private/prodotti/va/riskfree';
export const ValutazioneAziendaPaths = Object.freeze({
  nuovoAcquisto: `${baseUrl}/nuovo-acquisto`,
  telefono: `${baseUrl}/telefono`,
  countries: `${baseUrl}/countries`,
  simulateGestPay: `${baseUrl}/simulateGestpayCoupon`,
  priceRecovery: `${baseUrl}/priceRecovery`,
  traceRequest: `${baseUrl}/traceRequest`,
  updatePec: `${baseUrl}/updateCodiceDestinatarioPec`,
  load: `${baseUrl}/loadRiskFreeDefinition`,
  getDataFromVa: `${baseUrl}/get-data-from-va`,
});
