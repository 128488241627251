<template>
  <a :href="landingUrl">{{ landingText }}</a>
</template>

<script>
import { CauzioniPaths } from '../common/paths/cauzioni';
import { ValutazioneAziendaPaths } from '../common/paths/valutazioneAzienda';
import { AssicurazioniPaths } from '../common/paths/assicurazioni';

export default {
  props: {
    isAutenticated: {
      type: Boolean,
      default: false,
    },
    landingText: {
      type: String,
      default: 'Accedi',
    },
    params: {
      type: Object,
      default: null,
    },
  },
  computed: {
    landingUrl() {
      const queryParam = this.params.p;
      const assType = this.params.ass;
      const eventId = this.params.event;
      const contentId = this.params.article;
      const paese = this.params.paese;
      const progetto = this.params.project;
      const type = this.params.type;
      const content = this.params.content;

      switch (queryParam) {
      case 'wof':
      case 'LPEasyFactoring':
        return 'https://digitalfactoring.sacefct.it/';
      case 'Education':
        return 'https://www.sace.it/education/education-to-export';
      case 'assicurazione':
      case 'LPAssicurazione':
        return `${AssicurazioniPaths.preventivo}?assicurazione=${assType}`;
      case 'valutazioneazienda':
        return `${ValutazioneAziendaPaths.nuovoAcquisto}`;
      case 'Cauzioni':
      case 'LPCauzioni':
        return `${CauzioniPaths.base}/start`;
      // case 'CaroEnergia':
      //   return `${CauzioniPaths.base}/nuovaRichiesta?p=CAUZIONE_06_23`;
      case 'TEM':
        return '/private/servizi/lite/tem';
      case 'calendarioEventi':
        return `/private/servizi/lite/calendario-eventi/${eventId}`;
      case 'businessPromotion':
      case 'LPsaceConnects':
      case 'saceConnects':
        return progetto
          ? `/private/programmi/lite/sace-connects?p=${progetto}`
          : '/private/programmi/lite/sace-connects';
      case 'saceEducation':
        return contentId
          ? `/private/programmi/lite/sace-education/contenuti-formativi/${contentId}`
          : '/private/programmi/lite/sace-education';
      case 'LPsaceEducation':
        return '/private/programmi/lite/sace-education';
      case 'espertoRisponde':
        return '/private/servizi/lite/esperto-risponde';
      case 'consulenzaPersonalizzata':
        return '/private/servizi/lite/consulenza-personalizzata';
      case 'mappaSanzioni':
        return '/private/servizi/lite/mappa-sanzioni';
      case 'risk-map':
        return paese
          ? `https://www.sace.it/mappe/dettaglio/${paese}`
          : '/private/dashboard';
      case 'esg':
      case 'ESG':
        switch (type) {
        case 'education_dossier':
          return `/private/platform/esg/educationDossier/${content}`;
        case 'settori':
          return '/private/platform/esg/settori-futuro';
        case 'expert':
          return '/private/platform/esg?type=expert';
        case 'buyer':
          return '/private/programmi/lite/sace-connects?p=esg';
        case 'pda':
          return '/private/prodotti/assicurazione/preventivo';
        case 'solution':
          return `/private/platform/esg/soluzioni/${content}`;
        case 'rischio_clima_smart':
          return '/private/prodotti/protezione-clima';
        default:
          return '/private/platform/esg';
        }
      case 'rischio_clima_smart':
      case 'rischio_clima_smart_edu':
        return '/private/prodotti/protezione-clima';
      case 'sanctionsGPS':
        return '/private/servizi/lite/sanctionsGPS';
      case 'check-banche-estere':
        return '/private/prodotti/consultazione-plafond';
      default:
        return '/private/dashboard';
      }
    },
  },
  mounted() {
    if (this.isAutenticated) {
      window.location.href = this.landingUrl;
    }
  },
};
</script>
