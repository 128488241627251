/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
export const RecoMixin = {
  data() {
    return {
      setOrgId: '500008453',
      setUserInfo: {
        email: null,
      },
      trackPageView: {
        item: null,
        search: null,
        category: null,
      },
    };
  },
  computed: {
    scriptReco() {
      const setUserInfo = this.setUserInfo.email
        ? `_etmc.push(["setUserInfo", {"email": "${this.setUserInfo.email}"}]);`
        : '';
      const { trackPageView } = this;
      for (const key in trackPageView) {
        if (trackPageView[key] === null) {
          delete trackPageView[key];
        }
      }
      const hasData = Object.keys(trackPageView).length > 0;
      const pushTrackPageView = hasData ? `_etmc.push(["trackPageView", ${JSON.stringify(trackPageView)}])` : '_etmc.push(["trackPageView"])';

      // return `
      //   var _etmc = [];
      //   _etmc.push(["setOrgId", "${this.setOrgId}"]);
      //   ${setUserInfo}
      //   _etmc.push(["trackPageView"]);
      //   console.log('CIAO A TUTTI');`;
      return `
        var _etmc = [];
         _etmc.push(["setOrgId", "500008453"]);
        ${pushTrackPageView}
      `;
    },
    scriptRecoHead() {
      return ' <script type="text/javascript" async src="//500008453.collect.igodigital.com/collect.js"> </script>';
    },
  },
  watch: {
    scriptReco(val) {
      // console.log('script watch', val);
      // this.loadTrackingScript();
      // this.pushRecoScript();
    },
  },
  methods: {
    loadTrackingScript() {
      const script = document.createElement('script');
      script.id = 'recoTrackingCode';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = this.scriptReco;
      // document.body.appendChild(script);
      // script.src = '/assets/js/reco/recoTrackingCode.js';
      const firstScript = document.body.firstChild;
      document.body.insertBefore(script, firstScript);
    },
    pushRecoScript() {
      const recoTrackingCode = document.getElementById('recoTrackingCode');
      recoTrackingCode.innerHTML = this.scriptReco;
    },
    // executeScript() {
    //   const newScriptElement = document.createElement('script');
    //   newScriptElement.type = 'text/javascript';
    //   newScriptElement.async = true;
    //   newScriptElement.src = '//500008453.collect.igodigital.com/collect.js';
    //   newScriptElement.onload = function () {
    //     console.log('Script caricato ed eseguito.');
    //   };
    //   newScriptElement.onerror = function () {
    //     console.error('Errore nel caricamento dello script.');
    //   };
    //   document.head.appendChild(newScriptElement);
    // },
  },
  mounted() {
    // this.pushRecoScript();
  },
};
