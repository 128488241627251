/* eslint-disable import/prefer-default-export */
// private/assicurazione
const baseUrl = '/private/prodotti/assicurazione';
export const AssicurazioniPaths = Object.freeze({
  acquisto: `${baseUrl}/acquisto`,
  archiveToggle: `${baseUrl}/archiveToggle`,
  bpmComplete: `${baseUrl}/bpm-complete/`,
  bpmInstantiate: `${baseUrl}/bpm-instantiate`,
  dashboard: `${baseUrl}/dashboardAssicurazione`,
  deleteBozze: `${baseUrl}/bozze/delete`,
  detail: `${baseUrl}/detail`,
  downloadDoc: `${baseUrl}/download-documento`,
  getAssicurazioneById: `${baseUrl}/get-assicurazione-by-request-id`,
  getAssicurazioni: `${baseUrl}/get-assicurazioni`,
  getCompany: `${baseUrl}/get-company`,
  getCosto: `${baseUrl}/get-costo-totale`,
  getDetails: `${baseUrl}/get-operation-details`,
  getOperationVariation: `${baseUrl}/get-operations-and-variations`,
  getPdfVo: `${baseUrl}/get-pdf-vo`,
  getVariation: `${baseUrl}/get-variation-archive`,
  getVariationDoc: `${baseUrl}/get-variation-document`,
  loadDati: `${baseUrl}/load-dati-assicurazione`,
  loadOptionRisk: `${baseUrl}/load-option-risk-polizza`,
  preventivo: `${baseUrl}/preventivo`,
  referente: `${baseUrl}/referente`,
  rinuncia: `${baseUrl}/rinuncia`,
  savePreventivo: `${baseUrl}/save-dati-preventivo`,
  updateDati: `${baseUrl}/update-dati-assicurazione`,
  updateFigureGiuridiche: `${baseUrl}/update-figure-giuridiche`,
  writeBilancio: `${baseUrl}/writeFileBilancioGruppo`,
  writeDocumentazioneAggiuntiva: `${baseUrl}/writeDocumentazioneAggiuntiva`,
  writeFigureGIuridiche: `${baseUrl}/writeFileFigureGiuridiche`,
  writeFile: `${baseUrl}/writeFile`,
  getQuietanza: `${baseUrl}/getQuietanza`,
  downloadQuietanza: `${baseUrl}/downloadQuietanza`,
});
